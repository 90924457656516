import Cookies from 'js-cookie'

export function setUserId (user_id) {
    Cookies.set('class_user_id', user_id)
}
export function getUserId () {
    return Cookies.get('class_user_id')
}
export function removeUserId () {
    Cookies.remove('class_user_id')
}

export function setUserName (user_name) {
    Cookies.set('class_user_name', user_name)
}
export function getUserName () {
    return Cookies.get('class_user_name')
}
export function removeUserName () {
    Cookies.remove('class_user_name')
}


export function setUserType (user_type) {
    Cookies.set('class_user_type', user_type)
}
export function getUserType () {
    return Cookies.get('class_user_type')
}
export function removeUserType () {
    Cookies.remove('class_user_type')
}